import React, {Fragment, useEffect, useState} from "react";
import {Redirect, Route, Switch} from "react-router";
import "moment/locale/fr";
import moment from "moment";
import {changeOverlayContent} from "events/OverlayEvents";
import Header from "components/common/Header/Header";
import HomepageContainer from "components/HomepageContainer/HomepageContainer";
import TimesheetContainer from "components/TimesheetContainer/TimesheetContainer";
import ContactContainer from "components/ContactContainer/ContactContainer";
import NewsContainer from "components/NewsContainer/NewsContainer";
import GDPRContainer from "components/GDPRContainer/GDPRContainer";
import RunAsContainer from "components/RunAsContainer/RunAsContainer";
import MyAccountContainer from "components/MyAccountContainer/MyAccountContainer";
import Error from "components/common/Error";
import {getContactRoutes} from "config/Routes";
import TutorialContainer from "./TutorialContainer/TutorialContainer";
import DocumentContainer from "./DocumentContainer/DocumentContainer";
import ParametersContainer from "./ParametersContainer/ParametersContainer"
import "moment/locale/fr";
import GreenlyLink from "./common/GreenlyLink";
import Greenly2024PopUp from "./HomepageContainer/Greenly2024PopUp/Greenly2024PopUp";
import SecurityLink from "./common/SecurityLink";

const FrontendRoutes = (props) => {
    const {me} = props;
    // // Start 19/02/2024
    // const startGreenlyPopup = moment().set('year', 2024).set('month', 1).set('date', 19).format('YYYY-MM-DD')
    //
    const [popupVisible, setPopupVisible] = useState(!localStorage.getItem("security2024Hidden"));

    return (
        <Fragment>
            <Header/>
            {/*{me.type !== "contractor" && today >= startGreenlyPopup && <GreenlyLink setPopupVisible={setPopupVisible}/>}*/}
            {/*{me.type !== "internal" && <SecurityLink setPopupVisible={setPopupVisible}/>}*/}
            <Switch>
                <Route exact path="/" component={() => <HomepageContainer/>}/>
                <Route
                    path="/timesheet/:month?/:year?"
                    component={() => <TimesheetContainer/>}
                />
                <Route
                    strict
                    exact
                    path={getContactRoutes(props.me)}
                    component={() => <ContactContainer/>}
                />
                {me.type !== "internal" && (
                    <Route
                        exact
                        path="/account"
                        component={() => <MyAccountContainer/>}
                    />
                )}
                ner /
                <Route exact path="/parameters" component={() => <ParametersContainer personType={me.type}/>}/>

                <Route
                    exact
                    path="/actus/:postId?"
                    component={() => <NewsContainer/>}
                />
                <Route exact path="/rgpd" component={() => <GDPRContainer/>}/>
                <Route
                    exact
                    path="/tutorial"
                    component={() => <TutorialContainer/>}
                />

                {me.id && me.type !== "contractor" && (
                    <Route
                        exact
                        path={[
                            "/documents",
                            "/documents/:type",
                            "/documents/:type/:id",
                        ]}
                        component={() => <DocumentContainer/>}
                    />
                )}

                {me.hasRole("ROLE_ALLOWED_TO_SWITCH") && (
                    <Route
                        exact
                        path="/runas"
                        component={() => <RunAsContainer/>}
                    />
                )}

                <Route exact path="/login">
                    <Redirect to="/"/>
                </Route>

                {/*<Route*/}
                {/*    path="/error"*/}
                {/*    component={() => (*/}
                {/*        <Error label="Une erreur est survenue, veuillez recharger la page."/>*/}
                {/*    )}*/}
                {/*/>*/}

                {/*<Route*/}
                {/*    path="/maintenance"*/}
                {/*    component={() => (*/}
                {/*        <Error*/}
                {/*            label="Une maintenance est en cours, veuillez réessayer plus tard."*/}
                {/*            btnLabel="Recharger"*/}
                {/*            reloadTimeout={300000}*/}
                {/*        />*/}
                {/*    )}*/}
                {/*/>*/}

                <Route
                    path="*"
                    component={() => (
                        <Error
                            code={404}
                            label="La page demandée n'existe pas."
                        />
                    )}
                />
            </Switch>
        </Fragment>
    );
};

export default FrontendRoutes;
