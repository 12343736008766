import React from "react";
import "./VacationCountContent.scss";
import VacationCountTotals from "components/TimesheetContainer/VacationCount/VacationCountTotals";
import VacationCountHistory from "components/TimesheetContainer/VacationCount/VacationCountHistory";

const VacationCountContent = (props) => {
    return (
        <div className="vacation-content">
            <VacationCountTotals
                modality={props.modality}
                cp={props.cp}
                rtt={props.rtt}
                remainder={props.remainder}
                rttRemainder={props.rttRemainder}
                rest={props.rest}
                cp15={props.cp15}
                beforeJune={props.beforeJune}
                sickChildren={props.sickChildrenDays}
                displayRemainderCounter={props.displayRemainderCounter}
            />
            <VacationCountHistory/>
        </div>
    );
};

export default VacationCountContent;
